@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTedw.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_V1s.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1s.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;

  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1s.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1s.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1s.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4V1s.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1s.ttf)
    format("truetype");
}
*,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
input::placeholder {
  font-size: 12px;
}
input {
  font-size: 14px !important;
}
img {
  width: 100%;
}
p {
  margin: 0 !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400 !important;
}
span {
  font-family: "Roboto", sans-serif;
  margin-top: 7px;
  font-weight: 600 !important;
}
ul {
  padding-left: 0 !important;
}
ul,
li {
  list-style: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}
a {
  text-decoration: none !important;
  color: #696969 !important;
  cursor: pointer;
  font-family: "Roboto";
}
h1,
.heading {
  font-size: 60px;
  font-family: "Poppins";
  font-weight: 700;
}
.navbar-wrapper {
  position: relative;
}
.section-heading-wrapper {
  text-align: center;
  margin: 0 auto;
  max-width: 750px;
}
.section-padding {
  padding: 80px 0;
}
.section-padding-two {
  padding: 80px 30px;
}
.mr-xl-bottom {
  margin-bottom: 20px;
}
.mr-xl-top {
  margin-top: 20px !important;
}
.btns {
  display: block;
  background-color: #8eb359;
  border: 1px solid #8eb359;
  padding: 10px;
  text-align: center;
  border-radius: 50px;
  color: #fff;
  width: 250px;
}

.btn-outline {
  display: block;
  background-color: transparent;
  border: 1px solid #8eb359;
  padding: 10px;
  text-align: center;
  border-radius: 50px;
}
.btn-outline:hover {
  background-color: #8eb359;
  border: 1px solid #8eb359;
  color: #fff !important;
}
/* header start */
.header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  position: relative;
}
.header-wrapper .product-icons span i:hover {
  color: #8eb359 !important;
}
.product-icons span {
  margin-top: 2px !important;
}
.product-icons {
  margin-top: 15px;
}

.header-wrapper .product-icons span {
  padding: 10px;
}
.search-wrapper input::placeholder {
  padding-left: 2px;
}
.search-wrapper input {
  border-radius: 50px;
  border: 1px solid #8eb359 !important;
  text-indent: 20px;
  border: 1px solid;
  position: absolute;
  /* left: 76%; */
  right: 82px;
  top: 2px;
}
.search-wrapper input:focus {
  outline: none;
}
/* heade end */
/* user drop-down */
.user-container {
  position: absolute;
  right: -6px;
  background-color: #fbf8f9;
  padding: 10px;
  display: none;
  /* top: 48px; */
}
.user-container a {
  font-size: 14px;
  padding: 10px;
}
.user-sectiion:hover .user-container {
  display: block;
}
/* navigation start */

.search-show {
  display: block;
}

/* cart start herr */
.cart-badge {
  display: inline-block;
  background-color: #8eb359;
  width: 15px;
  height: 15px;
  color: #fff;
  position: absolute;
  border-radius: 50px;
  top: -6px;
  right: -9px;
  text-align: center;
  padding: 1px !important;
  font-size: 9px;
}
.closeIcon {
  cursor: pointer;
  transform: translate3d(-360px, 0px, 0px);
  color: #fff;
}

/* cart css end here */
.navbar-wrapper {
  position: relative;
  width: 100%;
}
.navbar-headers {
  display: flex;
  justify-content: space-between;
  padding: 10px 28px;
  position: relative;
  z-index: 1;
}
.navbar-headers .navbar-items-list,
.navbar-headers .product-icons {
  margin-top: 17px;
  display: flex;
  gap: 15px;
  position: relative;
}
.navbar-headers .navbar-items-list ul {
  display: flex;
  justify-content: space-between;
}
.navbar-headers .navbar-items-list ul li a {
  padding: 10px;
}
.navbar-headers .navbar-items-list ul li a:hover,
.navbar-headers .navbar-items-list ul li a.active,
.navbarItem-wrapper ul li a:hover {
  color: #8eb359 !important;
  border-bottom: 1px solid #8eb359;
}

.navbar-headers .buger-btn span {
  width: 38px;
  height: 4px;
  color: #696969;
  display: block;
  background-color: #696969;
  margin-top: 5px;
  cursor: pointer;
}
.navbar-headers .buger-btn {
  cursor: pointer;
  padding-top: 4px;
  display: none;
}

.navbarItem-wrapper ul li {
  padding: 10px;
  margin-top: 10px;
}
.closeItems {
  color: #000;
  margin-bottom: 20px;
  position: absolute;
  right: 31px;
  top: 0px;
  cursor: pointer;
}
/* .navbar-headers img,.navbar-headers .product-icons{
    display: none;
  } */

/* navigation end */
/* looking for */
.looking-wrapper {
  padding: 30px;
  border: 1px solid #000;
  border-radius: 10px;
}
/* looking for end */
/* footer section start */
.footer-wrapper {
  padding-top: 50px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 15px;
  background-color: #f8f9fa;
}
.footer-wrapper p {
  font-size: 14px;
}
.footer-wrapper span i {
  margin-right: 10px;
}
.footer-wrapper span,
.footer-wrapper span a {
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}
.icon-container {
  margin-top: 20px;
}
.icon-container i:hover {
  color: #8eb359;
}
.footer-wrapper span a:hover {
  color: #8eb359 !important;
}
.instagram-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}
/* footer-section end */
/* banner-section start */
/* banner-section end */
.banner-wrapper img {
  height: 650px;
  object-fit: cover;
}
.banner-section-wrapper .slick-dots {
  bottom: -35px;
}
.banner-section-wrapper .slick-dots li button:before {
  display: none;
}
.banner-section-wrapper .slick-dots li button {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ddd;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.banner-section-wrapper .slick-dots li.slick-active button {
  background-color: #8eb359;
  opacity: 1;
}
/* shop-wrapper section start */
.shop-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  gap: 20px;
}
.shop-wrapper .shap-inner-section {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.shop-wrapper p {
  margin-top: 10px;
}
.shop-wrapper .shap-inner-section:hover {
  border: 4px solid #8eb359;
}
/* shop wraper section end */
/* near */
.wrapper-inner {
  max-width: 374px;
  text-align: center;
  margin: 0 auto;
}
.wrapper-inner .btn-outline {
  margin: 0 auto;
}
/* best seller start */
.our-seller-wrapper {
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 10px auto;
  cursor: pointer;
}
.content-wrapper {
  padding: 20px;
}
.content-wrapper span i {
  color: #ffd200;
}
.our-seller-wrapper .btn-outline {
  margin: 0 auto;
}
/* best seller end */
/* blog section start */
.blog-wrapper {
  display: flex;
  padding: 0px 50px;
  gap: 15px;
}
.blog-inner-wrapper .data1 {
  margin-top: 20px;
}
.blog-inner-wrapper a:hover {
  border-bottom: 3px solid #8eb359 !important;
}
.blog-image {
  border: 1px solid #f4f4f4;
  overflow: hidden;
  border-radius: 10px;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.blog-image img:hover {
  transform: scale(1.2);
  border-radius: 10px !important;
}

/* blog section end */
/* testimonial start*/
/* Add your general styling here */
.testimonial-slider {
  width: 80%;
  margin: auto;
}
.testimonial-slider .slick-dots li.slick-active button {
  background-color: #8eb359;
  opacity: 1;
}
.testimonial {
  background: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.testimonial-content {
  margin-bottom: 20px;
  text-align: center;
}
.testimonial-content img {
  width: 70px;
  height: 70px;
  margin: 10px auto;
}
.testimonial-slider .slick-prev {
  font-size: 18px;
  top: 50%;
}
.testimonial-slider .slick-next {
  font-size: 18px;
  top: 50%;
}
.testimonial-slider .slick-prev:before,
.slick-next:before {
  color: #8eb359 !important;
}
/* You can add more styling as needed */

/*testimonial end /*
  
  /* Product detail page start */
.product-list-wrapper {
  padding: 20px;
  position: relative;
}
.price-containner .cut-price {
  text-decoration: line-through;
  color: #8eb359;
}
.badge-wrapper {
  position: absolute;
  top: 63%;
  left: 8%;
}
.badge-wrapper .badge-sale {
  padding: 10px;
  background-color: #8eb359;
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
}
.badge-wrapper .sold-out {
  padding: 10px;
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
}
.product-list-content {
  padding: 20px;
  text-align: center;
}
.fa-star {
  color: #ffd200;
}
.button-section {
  display: none;
}
.button-section .btns {
  margin: 0 auto;
}
.product-list-wrapper:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.product-list-wrapper:hover .button-section {
  display: block;
}
.img-wrapper img {
  border-radius: 10px;
}
/* product detil page start */
.product-detail-left img {
  border-radius: 10px;
}
.product-detail-right img {
  border-radius: 10px;
  padding: 5px;
}
.product-wrapper-content {
  padding: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.product-badges {
  margin-top: 20px !important;
}
.product-wrapper-content .product-badges .badgeOne,
.product-wrapper-content .product-badges .badgeTwo {
  padding: 10px;
  background-color: #eee;
  border-radius: 50px;
}
.product-price-content {
  padding: 10px;
}
.product-price-content .priceOne {
  font-size: 40px;
}
.product-price-content .cut-price-One {
  text-decoration: line-through;
  color: #8eb359;
}
.count-wrapper span {
  padding: 21px;
  color: #fff;
}
.count-wrapper {
  padding: 15px;
  background-color: #8eb359;
  width: 200px;
  border-radius: 50px;
  cursor: pointer;
}
.image-slide-wrapper {
  padding: 20px;
}

.product-detail-right .slick-prev:before,
.product-detail-right .slick-next:before {
  font-size: 20px;
  font-family: auto;
  color: #8eb359;
  border: 2px solid #8eb359;
  padding: 10px;
  border-radius: 10px;
}
.product-detail-right .slick-prev:before:hover,
.product-detail-right .slick-next:before:hover {
  background-color: #8eb359;
  color: #fff;
}
.product-detail-right .slick-prev {
  left: -51px;
}
/* Product detail page end */
/* login wrapper start */
.login-wrapper {
  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
}
.btn-wrapper .btns {
  width: 150px !important;
}
/* login wrapper end */
/* .category-wrapper{
    float: right;
  } */
.filter-wrapper {
  display: flex;
  justify-content: space-between;
}
.filter-left {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.price-ranger .range-slider .range-slider__thumb,
.price-ranger .range-slider .range-slider__range {
  background-color: #8eb359 !important;
}
.category-wrapper select {
  font-size: 16px !important;
  width: 200px;
  height: 40px;
  outline: none;
  cursor: pointer;
  color: #8eb359;
  margin-bottom: 10px;
  border: 1px solid #8eb359;

  top: 22px;
  right: 172px;
  padding: 10px;
  border-radius: 10px;
}
.category-wrapper select option {
  font-size: 16px !important;
}
.category-wrapper select option:checked {
  background-color: #8eb359;
  color: #fff;
}
.category-wrapper select option:hover {
  background-color: #8eb359; /* Change this to your desired color */
}
.delivery-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 10px;
  cursor: pointer;
}
.delivery-wrapper p {
  font-size: 12px;
}
.delivery-wrapper img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  display: block;
}
/* our story start */
.data-container {
  margin-top: 150px;
}

/* productDetailsection start */
.purDetailSection {
  padding: 20px;
}
.purDetailSection p {
  background-color: #8eb359;
  border-radius: 50px;
  padding: 10px;
  width: 200px;
  color: #fff;
  text-align: center;
}
.pureDetailWrapper {
  width: 100%;
  display: flex;
  gap: 15px 10px;
  margin-bottom: 35px;
  flex-wrap: wrap;
}
.pure-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: calc(50% - 6px);
}
.pure-wrapper img {
  width: 25px;
  margin-right: 15px;
}
/* productDetail section end */
/* our story end */
/* other css start*/
.banner-data{
  height: 500px;
}
textarea.form-control {
  font-size: 13px;
}
.mb-20 {
  margin-bottom: 20px;
}
.forget-text {
  font-size: 14px;
  text-decoration: underline !important;
}
.form-data-wrapper {
  padding: 25px !important;
  margin-top: 20px !important;
}
.login-wrapper,
.form-data-wrapper {
  padding: 20px;
  border: 1px solid #8eb359;
  border-radius: 10px;
}
.border-img-wrapper {
  border-radius: 10px;
}
.toastData {
  width: 100px;
  height: 20px;
}
.form-control.is-invalid {
  border-color: #dc3545;
}
.invalid-feedback {
  color: #dc3545;
  font-size: 80%;
}
.btn-colors {
  display: block;
  background-color: #eee;
  border: 1px solid #eee;
  padding: 10px;
  text-align: center;
  border-radius: 50px;

  width: 250px;
}
/* other css end */
/* cart setion start */
.cart_step_order {
  padding: 30px;
}
.cart_step_order ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart_step_order ul li {
  padding: 10px;
}
.cart_step_order ul li .checkData {
  color: #8eb359;
  border: 1px solid #8eb359;
  border-radius: 50px;
  font-size: 16px;
  padding: 3px;
  margin-right: 10px;
}
.cart_step_order ul li i {
  color: #eee;
  border: 1px solid #eee;
  border-radius: 50px;
  font-size: 16px;
  padding: 3px;
  margin-right: 10px;
}

.cart_step_order span::after {
  content: "";
  width: 50px;
  height: 1px;
  display: inline-block;
  background-color: #c3d2cc;
  margin: 3px 16px;
}
.cart_step_order ul li:last-child span::after {
  display: none;
}
.bold_text {
  color: #8eb359;
}
.image_product {
  width: 50px;
  height: 50px;
}
.checkout-summary {
  border: 1px solid #eee;
}
.cart-checkout-container {
  padding: 0 60px;
  display: flex;
  justify-content: space-evenly;
}
.cart-wrapper-content {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 10px;
}
.wrapper-cart-data {
  display: flex;
  gap: 20px;
  padding: 20px;
}
.data-remove-icon {
  font-size: 24px;
  color: #8eb359;
  cursor: pointer;
}
.cartValue,
.heading-cart {
  font-weight: 600;
}
.checkout-coupen-list {
  border: 1px solid #eeee;
  padding: 20px;
}
.card-form-data {
  display: flex;
  gap: 20px;
  padding: 10px;
}
.checkout-coupen-list h3 {
  border-bottom: 1px solid #eee;
}
.payable-data,
.order-summary {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  border-radius: 20px;
}
.shipping-cost {
  display: flex;
  justify-content: space-between;
  padding: 13px;
}
/* cart section end */
/* address start */
.address_container {
  position: relative;
}
.address_wrapper {
  padding: 30px;
  display: flex;
  gap: 20px;
}
.address_wrapper span button {
  font-size: 100px;
  color: #8eb359 !important;
  background-color: transparent;
  border: transparent;
}
.address_wrapper .address-span {
  padding: 20px;
  border: 1px solid #8eb359;
  text-align: center;
  display: inline-block;
  width: 200px;
}
.address_wrapper .address-para {
  margin-top: 20px;
  margin-left: 30px;
}
.add_inner_button {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}
.address-form-data {
  border: 1px solid #8eb359;
  padding: 13px;
  height: 250px;
  width: 250px;
  display: flex;
  justify-content: space-between;
}
.address-form-contents {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.address-icon-wrapper i {
  margin: 10px;
  color: #8eb359;
  cursor: pointer;
}
/* address end */
/* modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modals {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px;
}
.form-wrapper .form-data-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.form-wrapper {
  padding: 20px;
  width: 500px;
}
.form-data-container .form-control {
  width: 220px;
  height: 40px;
}
.btn_submit {
  float: right;
}
.btn_submit .btns {
  width: 150px;
}
/* payment start */
.payment-section {
  padding: 80px;
}
.payment-cash-features {
  padding: 20px;
  border: 1px solid #eee;
  height: 500px;
  background-color: #eeeeee52;
}
.payment-cash-features > div {
  margin-top: 20px;
}
.payment-wrapper {
  border: 1px solid #eee;
}
.payment-wrapper .payment-inner-wrapper > div {
  padding: 15px;
}

.payment-wrapper .payment-inner-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid #eee;
}
.payment-container {
  display: flex;
}
.payment-decription-wrapper {
  padding: 10px;
  width: 100%;
}
.payment-total-price {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.payment-shpping-charges .btns {
  width: 100px;
}
.payment-cash-features .btns,
.payment-cash-features .btn-colors {
  width: 304px;
}
/* payment end */
/* media query start */
@media screen and (max-width: 768px) {
  .section-padding-two{
    padding: 40px 20px;
  }
  .banner-data{
    height: 300px;
  }
  .product-icons span {
    padding: 10px;
    margin-top: -5px !important;
  }
  .search-wrapper span a i {
    margin-top: 8px !important;
  }
  .cart-badge {
    top: 5px;
    left: 89%;
  }
  .address_wrapper {
    display: block;
    text-align: center;
  }
  .address-form-data {
    margin: 0 auto;
  }
  .add_inner_button button {
    margin-bottom: 10px;
  }
  /* .navbar-headers img,.navbar-headers .product-icons{
      display: block;
    } */
  .header-wrapper {
    display: none;
  }
  .navbar-headers .navbar-items-list {
    display: none;
  }
  .navbar-headers .buger-btn {
    display: block;
    margin-top: 15px;
  }
  .shop-wrapper {
    flex-wrap: wrap;
  }
  .blog-wrapper {
    display: block;
  }
  .blog-wrapper .blog-inner-wrapper {
    margin-top: 20px;
  }
  .navbar-headers {
    justify-content: space-between;
  }
  .cart-checkout-container {
    display: block;
  }
  .search-wrapper input {
    right: -14px;
    top: 82px;
    width: 200px;
    height: 40px;
  }
  .user-container {
    right: 19px;
  }
}
@media screen and (max-width: 572px) {
  .btn-filter {
    display: flex;
    justify-content: center;
  }
  .filter-wrapper,
  .filter-left {
    display: block;
  }
  .btn-filter {
    margin: 0 auto;
  }
  .filter-wrapper,
  .btn-filter {
    padding: 10px;
    margin-top: 10px;
    text-align: center;
  }
  .cart_step_order ul {
    display: block !important;
    text-align: center;
  }
  .add_inner_button {
    display: block;
    text-align: -webkit-center;
  }
  .cart_step_order span::after {
    display: none;
  }
  .address_wrapper .address-para {
    margin-top: 7px;
  }
  .cart_step_order {
    padding: 10px;
  }
}
@media screen and (max-width: 425px) {
  .btn-filter {
    margin: 0 auto;
  }
  .filter-wrapper,
  .btn-filter {
    padding: 10px;
    margin-top: 10px;
    text-align: center;
  }
  .cart-wrapper-content,
  .order-summary,
  .shipping-cost,
  .payable-data,
  .filter-wrapper,
  .filter-left {
    display: block;
  }
  .wrapper-cart-data,
  .cart_step_order ul,
  .add_inner_button,
  .payment-container {
    display: block !important;
  }
}
/* medai query end */
.sidbarNavList {
  display: block;
  transition: transform 3s ease-in-out;
  position: absolute;
  background: #f4f4f4;
  width: 100%;
  top: 0;
  color: #fff;
  padding: 20px;
  z-index: 999;
  text-align: center;
  height: 900px;
}
.sideBarvisable {
  display: none;
  transition: transform 3s ease-in-out;
}

/* forgetPAssword start */
.forget-wrapper {
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #8eb359;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}
/* forget password end */
/* profile data start */
.profile-data-container {
  height: 400px;
  padding: 20px;
}
.profile-data-wrapper {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  border-radius: 10px;
  border: 1px solid #8eb359;
}
.profile-data-wrapper p {
  font-size: 16px;
  margin-top: 10px;
}
/* profile data end */
